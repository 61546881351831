// Page Layout
// -----------------------------------------------------------------------------
@font-face {
    font-family: "GothamProLight";
    src: url("../fonts/GothamPro-Light/GothamPro-Light.eot");
    src: url("../fonts/GothamPro-Light/GothamPro-Light.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GothamPro-Light/GothamPro-Light.woff") format("woff"),
    url("../fonts/GothamPro-Light/GothamPro-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: "GothamProBlack";
    src: url("../fonts/GothamPro-Black/GothamPro-Black.eot");
    src: url("../fonts/GothamPro-Black/GothamPro-Black.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GothamPro-Black/GothamPro-Black.woff") format("woff"),
    url("../fonts/GothamPro-Black/GothamPro-Black.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: "GeorgiaItalic";
    src:/* url("../fonts/GothamPro-Light/GothamPro-Light.eot");
    src: url("../fonts/GothamPro-Light/GothamPro-Light.eot?#iefix")format("embedded-opentype"),
    url("../fonts/GothamPro-Light/GothamPro-Light.woff") format("woff"),*/
    url("../fonts/Georgia-Italic/Georgia Italic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
.blur {
  -webkit-filter: blur(10px);
     -moz-filter: blur(10px);
       -o-filter: blur(10px);
      -ms-filter: blur(10px);
          filter: blur(10px); 
}
.navbar-collapse{
	border: none !important;
	box-shadow: 0 0 0 0 !important;
	position: relative !important;
}
.item-list{
	ul.pagination{
    width: 100%;
    text-align: center;
	}
 .pager-next, .pager-previous{
		a{
			position: relative;
			float: left;
			padding: 6px 12px;
			line-height: 1.42857;
			text-decoration: none;
			color: #fff;
			background-color: #e7e5d9;
			/* border: 1px solid #ddd; */
			margin-left: -1px;
			font-size: 26px;
			width: 50px;
			height: 50px;
			border-radius: 50% !important;
			padding:3px;
			font-weight: 700;
		}
	}	
	.pager-current{
		display: none !important;
	}
}
body{
	background: #c4bdb6;
}
body.node-type-articles{
	background: #fff;
}
body.page-o-vrache, body.page-usluga{
	background: #f8f4e7;
}
.uk.last{
	margin-left: 10px;
}
.view-i-healing-menu{
	z-index: 50;
	display: none;
	background: url(../images/bg-healing-menu.png) repeat;
	padding-top: 100px;
	padding-bottom: 100px;
	position: fixed;
	width: 100%;
	top: 50%;	
	.view-content{
	  	@media (max-width: 480px) {
			margin-top: 30px;
	  	}	
		a{
			color: #fff;
			text-transform: uppercase;
		    font-family: "GothamProLight";
		    line-height: 32px;
		    background: url(../images/bg-i-healing-menu-link.png) no-repeat;
		    padding-left: 20px;
	        background-position: 0px 4px;
	        margin-left: 20px;
		}
	}
  	@media (max-width: 480px) {
		background: #2c3855;
		padding-top: 40px;
		padding-bottom: 40px;
		position: fixed;
		width: 75%;
		top: 0;
		right: 0;
		height: auto;
		margin-top: 0px !important;
  	}		
	.view-footer{
		.container{
			position: relative;
		  	@media (max-width: 480px) {
				position: inherit;
		  	}				
		}
		a{
			background: #bc8a5f;
			font-family: "GothamProBlack";
			text-transform: uppercase;
		    padding-top: 7px;
		    padding-bottom: 7px;
		    padding-left: 50px;
		    padding-right: 50px;
		    border-radius: 30px;				
		    position: absolute;
		    margin-top: -23px;
		    right: 15px;
		    color: #fff;
		  	@media (max-width: 480px) {
				font-size: 10px;			  					    
				background: none;
			    padding-top: 0px;
			    padding-bottom: 0px;
			    margin-top: 0 !important;
			    top: 10px !important;
			    border: 1px solid #fff;
			    float:left;
		        line-height: 25px;
	            top: 16px !important;
			    left: 15px;
			    right: initial;		            
		  	}				    
		}
		a{
			&:hover{
				text-decoration: none;
			}
		}
	}
}	

    /*.view-i-healing-menu{
    	position: relative;
    	top: 0;
    	margin-top: 0!important;
	  	@media (max-width: 480px) {
				background: #2c3855;
				padding-top: 40px;
				padding-bottom: 40px;
				position: absolute;
				width: 75%;
				top: 0;
				right: 0;
				height: auto;
				margin-top: 0px !important;
	  	}	    	
    } */

#header{
	position: inherit;
	.navbar{
		margin-bottom: 0;
	}	
	.container{
		padding-left:0;
		padding-right:0;
	}
	@media (max-width: 780px) {
		background: #2c3855;
	} 		
	background: url(../images/bg-header.jpg) no-repeat;
	min-height: 988px;
	.region-under-logo{
		clear: both;
	}
	.navbar-default{
		background-color: transparent;
		border: none;

	}
	.navbar-default {
		.navbar-nav{ 
			&> .active{
	 			&> a{
 					background-color: transparent;
 					color: #fff;
	 			}
		 	}
		 }
		 .navbar-brand{
		 	width: 76px;
		 }
 	}
 	#main-menu{
 		margin-top: 45px;
 		float: right;
 		a{
 			font-family: "GothamProBlack";
 			font-size: 11px;
 			border-right: 1px solid #fff;
 			padding-top: 0;
 			padding-bottom: 0;
 		}
 		li.last{
 			a{
 				border-right: none;
 			}
 		}
 	}
 	.region-secondary-header-menu{
 		clear: both;
 		width: 600px;
 		position: relative;
		z-index:2;
 		padding-left: 15px;
 		top: 440px;
		@media (max-width: 1000px) {
			top: 300px;
		} 		
 		li{
 			background: url(../images/sec-menu-2.png) no-repeat;
 			width: 33%;
 			float: left;
		    background-position: 70px 5px;
 			a{
 				font-size: 24px;
 				&:after{
 					content: "подробнее";
 					/*padding: 30px;*/
 					padding-bottom: 0;
 					display: block;
 					clear: both;
 					color: #bc8a5f;
 					font-family: "GeorgiaItalic";
 					font-size: 15px;
 					text-transform: lowercase;
				  	@media (max-width: 480px) {
				  		content: "";	
				  	} 					
 				}
 				text-align: center;
 				background: url(../images/bg-second-menu.png) repeat-y right;
 				padding-bottom: 0;
 				padding-top: 60px;
 				padding-bottom: 20px;
 				&:hover{
 					font-size: 24px;
 				}
 			}
 		}
 		li.first{
		    width: 22%;
		    background: url(../images/sec-menu-1.png) no-repeat;
		    background-position: center 5px;
			a{
		 		text-align: center;
		 		padding-right: 0;
		 		padding-left: 0;
			}
 		}

 		li.last{
 			background: url(../images/sec-menu-3.png) no-repeat;
 			background-position: 70px 5px;
 			a{
 				text-align: center;
 				background:none; 
 			}
 		} 		
 	}
 	#header{
	 	.navbar-default{
	 		 .navbar-brand{
					width: 100% !important;
					height: auto !important;
 			}
 		}
 	}
 	.nav > li > a, .nav > li > a:hover, .nav > li > a:focus{
 		background-color: transparent;
 		font-family: "GothamProLight";
 		text-transform: uppercase;
 		color: #fff;
 		text-transform: uppercase;
 		font-size: 25px;
 	}
 	.region-under-logo{
	    clear: both;
    	width: 415px;
    	float: left;
    	padding-right: 15px;
    	margin-top: 8px;
 		a.ctools-use-modal{
		    color: #fff;
		    font-family: "GothamProBlack";
		    text-transform: uppercase;
		    background: #bc8a5f;
		    border-radius: 30px;
		    padding-top: 5px;
		    padding-bottom: 3px;
		    font-size: 11px;
		    padding-left: 40px;
		    padding-right: 40px;
				position: relative;
				z-index: 3;				
 		}
	 	.block.block-locale, .block.block-block{
	 		float: left;
	 	} 		
	 	.block.block-block{
	 		padding-top: 10px;
	 		float: right;
	 	}
 	}
 	.language-switcher-locale-url{
 		padding-left: 15px;
 	 	li{
 			float: left;
			position: relative;
			z-index: 4;
			&.ru {
				a {
					display: block;
					height: 25px;
					width: 51px;
					background: url('../images/bg-ru.png');
					text-indent: -9999px;
				}
				span{
					display: block;
					height: 25px;
					width: 51px;
					background: url('../images/bg-ru.png');
					text-indent: -9999px;					
				}
			}
			&.uk {
				a {
					display: block;
					height: 25px;
					width: 51px;
					background: url('../images/bg-ua.png');
					text-indent: -9999px;
				}
				span{
					display: block;
					height: 25px;
					width: 51px;
					background: url('../images/bg-ua.png');
					text-indent: -9999px;
				}
			}
 		}
 	}
 	.navbar-default{ .navbar-brand{
 		width: 415px;
		position: relative;
		z-index: 2;
 	}}
 	#logo{
	    display: block;
	    float: left;
	    margin-right: 15px;
	    height: auto !important;
	    width: 100%; 		
 	}
 	.navbar-header.col-md-6{
 		margin-top: 30px;
 	}
}
body.i18n-uk #header .region-secondary-header-menu li a:after {
	content:'детальніше';
}
body.i18n-uk #header.no-front .region-secondary-header-menu li a:after {
	content:""; 
}
#header{
    .navbar-default{
    	@media (max-width: 480px) {
        	background: #28334d;
        	min-height: 220px;
        }
    }	    
	.region-secondary-header-menu{
		@media (max-width: 480px) {
	    	background: #28334d;
	    	padding-top: 10px;
	    }   
		li {
			a{
				@media (max-width: 480px) {
					background: url(../images/bg-arrow-small.png) no-repeat;
				    background-position: 95% 82%;
				    padding-top: 40px;
				    padding-bottom: 5px;
				}				
			}
			@media (max-width: 480px) {
				width: 35% !important;
			}
		}	
		li.first {	
			@media (max-width: 800px) {	
		    	background: url(../images/nf-sec-menu-1.png) no-repeat;
		    	background-position: center 5px;	
	    		width: 25% !important;
	    	}
		}
		li.last {		
			@media (max-width: 800px) {	
			    background: url(../images/nf-sec-menu-3.png) no-repeat;
			    background-position: 53px 5px;	
			}
		    a{
				@media (max-width: 480px) {
					background: url(../images/bg-arrow-small.png) no-repeat;
				    background-position: 95% 82%;
				}	
		    }		
		}			     
	}
}
#header.no-front{

	background: url(../images/bg-no-front.png) no-repeat !important;
	background-size: cover;
	min-height: 144px;
	z-index: 1 !important;
	@media (max-width: 800px) {
    	/*background: #28334d !important;*/
    }
    .backstretch{
    	height: auto !important;
    }
    .navbar-default{
    	@media (max-width: 800px) {
        	background: #28334d;
        	min-height: 220px;
        }
    }
	#main-menu{
		margin-top: 35px;
	}
	.region-secondary-header-menu{
		top: 0;
		width: 36%;
		float:right;
		clear: none;
		top: 20px;
		@media (max-width: 480px) {
	    	padding-top: 0px;
	    }		
		@media (max-width: 800px) {
	    	background: #28334d;
	    }
		li {
			a{
				font-size: 11px !important;
				padding-bottom: 10px;
				padding-top: 40px;
				&:after{
					content: "";
				}	
				@media (max-width: 480px) {
					background: url(../images/bg-arrow-small.png) no-repeat;
				    background-position: 95% 76%;
				}				
			}
		    background: url(../images/nf-sec-menu-2.png) no-repeat;
		    background-position: 53px 5px;
		    width: 35%;
		}	 
		li.first {		
		    background: url(../images/nf-sec-menu-1.png) no-repeat;
		    background-position: center 5px;	
	    	width: 25%;
		}
		li.last {		
		    background: url(../images/nf-sec-menu-3.png) no-repeat;
		    background-position: 53px 5px;	
		    a{
				@media (max-width: 480px) {
					background: url(../images/bg-arrow-small.png) no-repeat;
				    background-position: 95% 76%;
				}	
		    }		
		}		
	}
}
.front{
	.paragraphs-items-field-about-me{
		&:after{
		    content: "";
		    background: url("../images/bg-face.png") no-repeat;
		    padding-bottom: 0;
		    padding: 100px;
		    padding-bottom: 90px;
		    position: absolute;
		    left: 50%;
		    margin-left: -110px;
		    bottom: -90px;
		    z-index: 2;
		}
	}
	.view-id-usefull_articles{
		padding-bottom: 70px;
	}
	#flexslider-1{ 
			@media (max-width: 640px) {
				background: #2c3855;
				top: 0px;
				p{
					padding: 10px;
				}
			}		
		.views-field-body{
			@media (max-width: 640px) {
				background: #2c3855;
				top: 0px;
				p{
					padding: 10px;
				}
			}	
		}
	}
	.bg50{
		@media (max-width: 640px) {
			height: 60%;
		}
	}
	.backstretch{
		@media (max-width: 768px) {
			top: 128px !important;
		}	
	}
	.flexslider{
		&:hover{
			.flex-direction-nav{
				.flex-next{
					@media (max-width: 480px) {
						right: 30%;
						top: 40%;
					}							
				}
				.flex-prev{
					@media (max-width: 480px) {
						left: 30%;
						top: 40%;
					}					
				}
			}
		}
	}	
	.views-field-field-slider-image{
		img{
			-webkit-border-top-left-radius: 30px;
			-webkit-border-bottom-right-radius: 30px;
			-moz-border-radius-topleft: 30px;
			-moz-border-radius-bottomright: 30px;
			border-top-left-radius: 30px;
			border-bottom-right-radius: 30px;
		}
	}

}
#aboutme{
	padding-bottom: 170px;
	position: relative;
}
#biography{
	padding-top: 100px;
	color: #cccace;
	padding-top: 150px;
	padding-bottom: 65px;
	.pane-title{
		margin: 0;
		padding-bottom: 35px;
	}
	.field-name-body{
		line-height: 26px;
	}
}
#readmore-biography{
    text-align: center;
    background: #2c3855;
    clear: both;
    padding-top: 13px;
    padding-bottom: 70px;
	a{
		color: #fff;
		border: 1px solid #fff;
		padding-top: 13px;
		padding-bottom: 10px;
		padding-left: 30px;
		padding-right: 30px;
		text-transform: uppercase;
		-webkit-border-radius: 30px;
		-moz-border-radius: 30px;
		border-radius: 30px;		
	}
}
.pane-slider-slider{
	background: #f8f4e7;
	.pane-content{
		position: relative;
	}
	.flexslider{
		background: transparent;
		border: none;
		margin: 0 0 40px;
	}
}
.front{
	.pane-slider-slider{
		.bg50{
			position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 43%;
		    background: #2c3855;
		}
	}
}
h2.pane-title{
	text-align: center;
	text-transform: uppercase;
	font-weight: 700;
	font-family: Open Sans;
	font-size: 14px;
}
.flexslider{ .slides{ img{
	-webkit-border-top-left-radius: 30px;
	-webkit-border-bottom-right-radius: 30px;
	-moz-border-radius-topleft: 30px;
	-moz-border-radius-bottomright: 30px;
	border-top-left-radius: 30px;
	border-bottom-right-radius: 30px;
}}}
.flexslider{ .slides { &> li{
	-webkit-border-top-left-radius: 30px;
	-webkit-border-bottom-right-radius: 30px;
	-moz-border-radius-topleft: 30px;
	-moz-border-radius-bottomright: 30px;
	border-top-left-radius: 30px;
	border-bottom-right-radius: 30px;
}}}
.flex-control-nav{
	display: none;
}
#flexslider-1{ 
	.views-field-body{
    	position: relative;
    	width: 100%;
		bottom: 80px;
		color: #fff;
		text-align: center;
	}
}
.flex-direction-nav{
	a.flex-next{
	    width: 50px;
    	height: 50px;
		&:before{
			background: url(../images/bg-next.png) no-repeat;
			content: " ";
		    padding-top: 50px;
		    padding-left: 50px;			
		}
	}
} 
.flex-direction-nav{
	a.flex-prev{
		&:before{
			background: url(../images/bg-prev.png) no-repeat;
			content: " ";
		    padding-top: 50px;
		    padding-left: 50px;			
		}
	}
} 
.flexslider:hover .flex-direction-nav .flex-prev{
	opacity: 1;
    width: 50px;
	height: 50px;	
}
.flexslider:hover .flex-direction-nav .flex-next{
	opacity: 1;
    width: 50px;
	height: 50px;	
}
.flex-direction-nav {
	a{
		top: 45%;
	}
}
#usefull-articles{
	background: #f8f4e7;
	.pane-title{
		margin: 0;
	}
}
.view-usefull-articles{
	.views-row{
		text-align: center;
		font-family: Open Sans;
		height: 360px;
	}
	.views-field-created{
		text-transform: uppercase;
		background: #2c3855;
		color: #f8f4e7;
		width: 72px;
		height: 72px;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		padding-top: 13px;
		margin: 0 auto;
	}
	.views-field-title{
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 700;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.views-field-title a{
		color: #2c3855;
		line-height: 18px;
	}
	.views-field-view-node{
		margin-top: 50px;
		margin-bottom: 50px;		
	}
	.views-field-view-node a{
		border: 1px solid #2c3855;
		color: #2c3855;
		font-family: Open Sans;
		text-transform: uppercase;
		-webkit-border-radius: 30px;
		-moz-border-radius: 30px;
		border-radius: 30px;
		padding: 13px 33px;	
		&:hover{
			border: 1px solid #bc8a5f;
			background: #bc8a5f;
			color: #fff;
			text-decoration: none;
		}
	}
	.views-row{
		margin-top: 35px;
		padding-top: 30px;
		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		border-radius: 15px;		
	}
	.views-row{
		&:hover{
			background: #fbf8f1;
			.views-field-created{
				background: #fff;
				color: #2c3855;
				cursor: pointer;
			}			
		}
	}
}
#footer{
	background: #2c3855;
	padding-top: 50px;
	margin-bottom: 0;
	clear: both;
	margin: 0;
	.bottom, .popup-bottom, .all-rights{
		text-align: center;
		color: #fff;
	}
	.all-rights {
		margin-bottom: 30px;
	}
	.popup-bottom a{ 
		border: 1px solid #bc8a5f;
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		border-radius: 20px;
		text-transform: uppercase;
		color: #fff;
		margin-top: 10px;
		margin-bottom: 10px;
		padding-left: 30px;
		padding-right: 30px;		
	}
}
#aboutme{
	background: #c4bdb6;
	font-size: 14px;
	padding-top: 70px;
	.pane-title{
		font-family: Open Sans;
		font-weight: 700;
		text-transform: uppercase;
		color: #514c4e;
		margin: 0;
		padding-bottom: 40px;
	}
	.content{
		text-align: center;
		color: #514c4e;
	}
	.field-name-field-about-image{
		padding-bottom: 25px;
	}
}
#biography{
	background: #2c3855;
}
.page-contacts{
	background: #fff;
	h1{
		text-align: center;
		text-transform: uppercase;
		font-weight: 700;
		font-family: Open Sans;
		font-size: 14px;
		color: #bc8a5f;		
	}
	.page-header{
		margin: 0;
		border-bottom: 0;
		padding-top: 80px;
	}
	.pane-1,.pane-2{
		max-width: 440px;
		margin: 0 auto;
		padding-top: 30px;
		line-height: 26px;
		color: #514c4e;
	}
	.pane-2{
		padding-top: 0;
		.phones{
			font-family: Open Sans;
			font-weight: 700;
			width: 50%;
			float: left;
			text-align: center;
			font-size: 17px;
			span{
				color: #bc8a5f;
			}
		}
	}
	.pane-3{
		padding-top: 40px;
		padding-bottom: 40px;
		p{
			margin: 0;
			text-align: center;
			a {
				font-family: Open Sans;
    			font-weight: 700;
    			text-decoration: none;
    			color: #514c4e;
			}
		}
	}
	.pane-1,.pane-2,.pane-3{
		clear: both;
	}
}
.owl-carousel{
	width: 100% !important;
	.owl-item{
	 	 img{
	 	 	width: initial !important;
 		}
	}
	.owl-pagination{
		display: none;
	}
	.owl-item{
		text-align: center;
	}
	.owl-prev{
		left: 1% !important;
		position: absolute !important;
		top: 45% !important;					
		background: url(../images/caleft.png) no-repeat !important;
		padding: 9px !important;
	}
	.owl-next{
		right: -1% !important;
		position: absolute !important;
		top: 45% !important;	
		background: url(../images/caright.png) no-repeat !important;
	
	    width: 10px;
	    height: 17px;		
	}
	.owl-wrapper-outer{
		width: 100% !important;
	}	
}
.field-name-field-carousel-image{
	width: 85% !important;	
	margin: 0 auto;
}
.webform-client-form-16,.webform-client-form-53, .webform-client-form-28, .webform-client-form-64 {
	max-width: 805px;
	margin: 0 auto;
	.form-actions{
		clear: both;
	}
}
.webform-client-form-28,
.webform-client-form-64 {
	.form-actions{
		clear: both;
		padding-bottom: 70px;
	}
}
.roundabout-controls{
    z-index: 12 !important;
    position: absolute !important;
    top: -150px !important;
}
.page-o-vrache{
	.pane-custom.pane-1{
	    text-align: center;
	    padding-top: 70px;
	    background: #fff;
	    p{
	    	margin: 0;
	    }
	}
	#biography{
		background: #fff;
		padding-top: 60px;
		.pane-title{
			text-align: center;
			text-transform: uppercase;
			font-weight: 700;
			font-family: Open Sans;
			font-size: 14px;
			color: #bc8a5f;		
		}
		.node-biography{
			max-width: 700px;
			margin: 0 auto;
			color: #514c4e;
			.node-readmore{
				text-align: center;
				padding-top: 60px;
			}
			.node-readmore a{
			    color: #2c3855;
			    border: 1px solid #bc8a5f;
			    padding-top: 11px;
			    padding-bottom: 10px;
			    padding-left: 30px;
			    padding-right: 30px;
			    text-transform: uppercase;
			    border-radius: 30px;			
			}
		}
	}
	#aboutme{
		background: #2c3855;
		padding-bottom: 80px;
		.content, .pane-title{
			color: #ffffff;
		}
	}
	.pane-slider-slider{
		padding-top: 70px;
	}
}
#videomessage{
	margin-top: 40px;
	background: #f8f4e7;
	.node-readmore{
		display: none;
	}
	.pane-title{
		margin-top: 0;
	}
	/*background: #f8f4e7 url(../images/bg-videomessage.png) no-repeat;*/
	.field-name-body{
		p{
			img{
				margin-right: 1%;
			}
		}
	}	
	.show_video, .links.inline	{
		clear: both;
	}
	.col-lg-8 {
		img{
			margin-left: 16.6%;
			float:left;
			@media (max-width: 991px){
				display: inline-block;
				margin: 0 auto;
				float: none;
				margin-left: 0px!important;
			}
		}
		p{
			max-width: 510px;
			margin-left: 12%;
			font-family: Times New Roman;
			font-style: italic;
			font-size: 21px;
			color: #868b96;
			float: left;
			background: url(../images/videomessagedots.png) no-repeat;
			padding-left: 70px;
			@media (max-width: 991px) {
				padding: 0px;
				margin: 0px;
				max-width: 100%;
				background-position: top center;
				padding-top: 40px;
				text-align: center;
			}
		}
	}
	p{
		float: left;
		margin-bottom: 0;
		img{
			margin-top: -70px;
			@media (min-width: 992px) and (max-width: 1119px){
				margin-top:0px;
				max-width: 100%;
    			height: auto;
			}
			@media (max-width: 991px){
				display: none;
			}
		}
	}
	.show_video{
		margin-left: 12%;
		padding-left: 70px;
		text-transform: uppercase;
		color: #bc8a5f;
		padding-top: 50px;
		@media (max-width: 991px) {
			padding: 0px;
			margin: 0px;
			max-width: 100%;
			background-position: top center;
			padding-top: 40px;
			text-align: center; 
		}
		.txt-1{
			font-family: "GothamProLight";
			font-size: 21px;
		}
		.txt-2{
			font-family: "GothamProBlack";
			font-size: 41px;
			line-height: 41px;
			p{
				background: none;
			    max-width: inherit;
			    margin-left: 0;
			    color: inherit;
			    float: none;
			    clear: both;
			    background: none;
			    img{
			    	margin-top: 30px;
			    	margin-left:120px;
			    	cursor: pointer;
			    }
			}
		}
	}
}
#certificates{
	background: #fff;
	padding-bottom: 75px;
	.pane-title{
		margin-top: 0;
		padding-top: 70px;
		margin-bottom:0px;
		/*&:after{
			content: "Все дипломы, сертификаты, свидетельства и др.";
		    color: #514c4e;
		    font-size: 13px;
		    text-transform: initial;
		    clear: both;
		    display: block;
		    padding-top: 7px;
		    font-family: Open Sans;
		    font-weight: normal;  
		    padding-bottom: 40px;		
		}*/
	}
	.field-name-field-subtitle {
		color: #514c4e;
	    font-size: 13px;
	    text-transform: initial;
	    clear: both;
	    display: block;
	    padding-top: 7px;
	    font-family: Open Sans;
	    font-weight: normal;
	    padding-bottom: 40px;
	    text-align: center;
	    margin-bottom:10px;
	}
}
.webform-component-textarea{
	 .grippie{
		display: none;
	}
}
.pane-webform-client-block-16, .pane-webform-client-block-28{
	.pane-title{
		padding-top: 70px;
		margin-top: 0;
		&:after{
			content: "При записи на прием онлайн вы получаете бесплатную консультацию";
		    color: #514c4e;
		    font-size: 13px;
		    text-transform: initial;
		    clear: both;
		    display: block;
		    padding-top: 7px;
		    font-family: Open Sans;
		    font-weight: normal;
		    padding-bottom: 40px;		
		} 
	}
}
.pane-webform-client-block-28,
.pane-webform-client-block-53{
	background: #f8f4e7;
	margin-top: 60px;
	.pane-title{
		&:after{
			content: "При записи онлайн вы получаете скидку 20%";
		} 
	}
	.webform-component--nomer-telefona input[type="text"],
	.webform-component--vashe-imya input[type="text"],
	.webform-component--nomer-telefonu input[type="text"]{
		width: 100% !important;
	}
	.form-actions{
		margin: 0 !important;
	}
}
#webform-client-form-16,#webform-client-form-53, .webform-client-form-28, .webform-client-form-64{
	input[type="text"], input[type="email"], textarea{
		font-family: Open Sans;
		border: none;
		border-radius: 30px;
	}
	.form-actions{
		text-align: center;
	}
	.webform-component--vashe-imya input[type="text"]{
		width: 75%;
		float:right;
	}
	.webform-component--nomer-telefona input[type="text"],
	.webform-component--nomer-telefonu input[type="text"]{
		width: 75%;
		float:left;
	}
	.webform-component--kommentariy,
	.webform-component--komentar{
	    position: relative;
    	left: 50%;
    	margin-left: -33%;
    }
    .btn-primary{
    	background: none;
    	color: #2c3855;
    	font-family: Open Sans;
    	text-transform: uppercase;
		border: 1px solid #bc8a5f;
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		border-radius: 20px;
		padding-left: 30px;
		padding-right: 30px;
		margin-bottom: 70px;
		margin-top: 30px;
    }
    .webform-component--e-mail, .webform-component--data-vizita, .webform-component--vremya-vizita, textarea,
    .webform-component--data-vizytu, .webform-component--chas-vizytu
    {
    	margin-top: 15px;
    }
}
.webform-client-form-28,
.webform-client-form-64{
	.webform-component--e-mail{
		margin-top: 0;
	} 
}
#about_webform {
	h2 {
		text-align: center;
	    text-transform: uppercase;
	    font-weight: 700;
	    font-family: Open Sans;
	    font-size: 14px;
	    padding-top: 70px;
	    margin-top: 0;	
	    margin-bottom:0px;
	}
	.field-name-field-subtitle {
	    color: #514c4e;
	    font-size: 13px;
	    text-transform: initial;
	    clear: both;
	    display: block;
	    padding-top: 7px;
	    font-family: Open Sans;
	    font-weight: normal;
	    padding-bottom: 40px;
	    text-align: center;
	    margin-bottom: 10px;
	}
}

.page-usluga{
	.pane-title{
		color: #fff;
	}
	.field.field-name-field-us-image{
		margin-bottom: 25px;
		color: #fff;
	}
	#aboutme.about_me{ 
		background: #2c3855;
		p, h2.pane-title{
			color: #fff;
		}
	}
	#about_service{
		background: #f8f4e7;
		h2.pane-title{
			margin-top: 0;
			color: #bc8a5f;
			padding-top: 70px;
			padding-bottom: 25px;
		}
		.field-type-text-with-summary{
			max-width: 900px;
			text-align: center;
			margin: 0 auto;
			padding-bottom: 100px;
			color: #514c4e;
			font-size: 14px;
			line-height: 24px;
		}
	}
	#know_more{
		background: #fff;
		h2.pane-title{
			margin-top: 0;
			color: #000;
			padding-top: 70px;
			padding-bottom: 25px;
		}	
		.field-type-text-with-summary{
			line-height: 26px;
			padding-bottom: 100px;
			float: left;
		}
	}
	.pane-title{
		color: #2c3855;
	}
	#banner{
		img{
			width: 100%;
			height: auto !important; 
		}
		padding-bottom: 70px;
	}
	#services-text{
		padding-top: 70px;
		padding-bottom: 100px;
		background: #fff;
		.field-name-body{
		    max-width: 900px;
		    text-align: center;
		    margin: 0 auto;
		    color: #514c4e;
		    font-size: 14px;
		    line-height: 24px;
		}
	}
}
.page-ya-obuchayu{
	background: #fff;
	#study-up{
		background: url(../images/bg-study-up.png) no-repeat;
	}
	.flex-control-nav{
		display: block;
	}
	.jcarousel-skin-tango{
		.jcarousel-item{
			width: 287px;
			margin-left:10px;
			margin-right:10px;
			height: 218px;
		}
		.jcarousel-clip-horizontal, .jcarousel-container-horizontal{
			width: 920px;
		}
		.jcarousel-container-horizontal{
			padding: 0 !important;
		    border: none;
		    background: none;	
		    height: inherit;		
		}
		.jcarousel-prev-horizontal{
			background: url(../images/caleft.png) no-repeat !important;
			left: -50px;
		}
		.jcarousel-next-horizontal{
			background: url(../images/caright.png) no-repeat !important;		
			right: -50px;
		}
		.jcarousel-next-horizontal, .jcarousel-prev-horizontal{		
			width: 10px;
			height: 17px;
			opacity: 0.7;
			cursor: pointer;
			top: 50%;
			margin-top: 21px;
		}
		.jcarousel-next-horizontal, .jcarousel-prev-horizontal{	
			&:hover{
				opacity: 1;
			}
		}
		.jcarousel-prev-disabled, .jcarousel-next-disabled{
			opacity: 0.7;
			cursor: default;
		}
		.flex-control-nav{
			margin-top: 50px !important;
		}
	}
	.hide_carousel{
		display: none;
	}
	.flexslider{
		margin: 0;
	}
	.field-name-body, .views-field-body{
		text-align: center;
		padding-bottom: 100px;
		line-height: 25px;
		margin-top: 60px;
	}
	.views-field-body{
		.field-content{
			max-width: 900px;
			margin: 0 auto;
		}
	}
	#study-up{
		text-align: center;
		padding-top: 70px;
		padding-bottom: 70px;
		.field-name-field-study-city, .views-field-field-study-city{
			display: inline-block;
			padding-left: 10px;
			padding-right: 10px;
			background: #bc8a5f;
			color: #fff;
			font-family: Open Sans;
			font-weight: 400;
			-webkit-border-radius: 30px;
			-moz-border-radius: 30px;
			border-radius: 30px;
			font-size: 13px;
			padding-top: 8px;
			padding-bottom: 8px;
			line-height: 13px;
			margin: 0 auto;
			margin-top: 10px;
			margin-bottom: 30px;
		}
		.field-name-field-study-upper-txt, .views-field-field-study-upper-txt{
			font-family: Open Sans;
			font-weight: 400;	
			color: #2c3855;
			font-size: 31px;
			max-width: 720px;
			margin: 0 auto;
		}
		.node-readmore{
			display: none;
		}
	}

}
.node-type-i-speak {
	background: #fff;
	#page-header{
		display: none; 
	}
	h2{
		color: #bc8a5f;
		font-weight: 700;
		font-size: 14px;
	}
	.field-name-field-ispeak-image{
		float: left;
		padding-right: 50px;
		padding-bottom: 35px;
	}
	.field-name-body{
		margin-top: 70px;
		margin-bottom: 45px;
	}
	.field-name-body .field-item{
	    padding-left: 28%;
	}
	audio{
		width: 100%;
		background: #f9f5e9;
	}
	.field-name-field-i-speak-video{
		clear: both;		
	}
	.media-youtube-video{
		text-align: center;
		padding-top: 50px;
		margin-bottom: 115px;
	}
	.field-name-field-text-2{
		max-width: 820px;
		float: right;
		margin-top: 25px;
	}
}
.page-feedbacks{
	.view-about-me-talk{
		padding-left: 0;
		.view-header {
			text-align: center;
		    color: #514c4e;
		    font-size: 13px;
		    text-transform: initial;
		    clear: both;
		    display: block;
		    padding-top: 7px;
		    font-family: Open Sans;
		    font-weight: normal; 
		    padding-bottom: 40px;	
		    padding-left:15px;
		}
	}
	background: #fff;
	.view-about-me-talk{
		max-width: 930px;
		margin: 0 auto;
		margin-bottom: 75px;
		.views-row{
			.views-field-field-feedback-name{
				border-bottom: 2px solid #f8f4e7;
				color: #bc8a5f;
				padding-top: 25px;
				padding-bottom: 25px;
			}
		}
	}
	.views-row{
		padding-left: 60px;		
		margin-top: 25px;
		p{
			margin-bottom: 0;
		}
	}
	.views-row-odd{
		background: url(../images/dots1.png) no-repeat;
	}
	.views-row-even{
		background: url(../images/dots2.png) no-repeat;
	}
	.page-header{
		margin:0;
		padding: 0;
		border: none;
	}
	h1{
		font-family: Open Sans;
		font-weight: 700;
		text-transform: uppercase;
		color: #514c4e;
		margin: 0;
		font-size: 14px;	
		text-align: center;		
		color: #bc8a5f;
		margin-top: 75px;
		/*&:after{
			content: "Отзывы пациентов";
		    color: #514c4e;
		    font-size: 13px;
		    text-transform: initial;
		    clear: both;
		    display: block;
		    padding-top: 7px;
		    font-family: Open Sans;
		    font-weight: normal;
		}	*/	
	}
}
.page-ya-govoryu, .page-ya-obuchayu{
	background: #fff;
	.page-header{
		border-bottom: none;
		margin-bottom:0px;
		padding-bottom:0px;
		h1{
			font-family: Open Sans;
			font-weight: 700;
			text-transform: uppercase;
			color: #514c4e;
			margin: 0;
			font-size: 14px;	
			text-align: center;		
			color: #bc8a5f;
			margin-top: 75px;
			font-size: 14px;
			/*&:after{
				content: "Раздел полезных статей, аудио и видео материалов";
				color: #514c4e;
				font-size: 13px;
				text-transform: initial;
				clear: both;
				display: block;
				padding-top: 7px;
				font-family: Open Sans;
				font-weight: normal;
			}*/
		}		
	}	
	.view-header {
		text-align: center;
		padding-bottom: 40px;	
		color: #514c4e;
		font-size: 13px;
		text-transform: initial;
		clear: both;
		display: block;
		padding-top: 7px;
		font-family: Open Sans;
		font-weight: normal;
		margin-bottom:18px;
	}	
}
/*
.page-ya-obuchayu{
	.page-header{
		h1{
			&:after{
				content: "Список анонсов различных мероприятий";
			}
		}
	}
}
*/
.field-name-field-study-date, .views-field-field-study-date{
	width: 80px;
	height: 80px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background: #2c3855;
	margin: 0 auto;
	.date-1, .date-2{
		font-family: Open Sans;
		font-weight: 400;
	}
	.date-1{
		color: #bc8a5f;
		font-size: 35px;
			line-height: 35px;				
	}
	.date-2{
		font-size: 14px;
		color: #fff;
			line-height: 14px;
	}
	.field-items, .field-content{
			position: relative;
			top: 12px;
	}
}
.node-i-speak{
	position: relative;
	.field-name-field-date{
		position: relative;
		.field-item{
		    position: relative;
		    top: 13px;		
		}
		.field-items{
			position: absolute;
		    top: 70px;
		    left: 40px;	
			width: 80px;
			height: 80px;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			background: #2c3855;		    
		}
		.date-1, .date-2{
			font-family: Open Sans;
			font-weight: 400;
		}
		.date-1{
			color: #bc8a5f;
			font-size: 35px;
			line-height: 35px;	
			text-align: center;			
		}
		.date-2{
			font-size: 14px;
			color: #fff;
			line-height: 14px;
			text-align: center;
		}
	}
}
.view-ya-gov{
	.col-lg-10{
		float: right;
	}
	.wrop{
		position: relative;
	}
	.views-row{
		position: relative;
	}
	.wrop{
		.date-display-single{
			width: 80px;
			height: 80px;
			-webkit-border-radius: 50%;
			-moz-border-radius: 50%;
			border-radius: 50%;
			background: #2c3855;
			margin: 0 auto;
			position: absolute;
			.date-1, .date-2{
				font-family: Open Sans;
				font-weight: 400;
			}
			.date-1{
				color: #bc8a5f;
				font-size: 35px;
				line-height: 35px;	
				text-align: center;		
				position: relative;
				top: 12px;				
			}
			.date-2{
				font-size: 14px;
				color: #fff;
				line-height: 14px;
				text-align: center;
				position: relative;
				top: 12px;				
			}
			.field-content{

			}
			bottom: 30px;
			left: 30px;	
			z-index: 2;		
		}
	}
	.views-field-field-ispeak-image, .views-field-field-preview{
		text-align: right;
		img{
			width: 100%;
			height: auto !important;
		}
	}
	.views-field-body{
		margin-top: 0 !important;
		padding-bottom: 0 !important;
		text-align: left;
	}
	.views-field-title{
		margin-top: 25px;
		margin-bottom: 40px;
		.field-content{
				color: #bc8a5f;
				font-weight: 700;
				font-face: Open Sans;
			}
	}
	.views-field-body{
		color: #514c4e;
		line-height: 24px;
	}
	.views-field-view-node{
			margin-top: 50px;
			text-align: left;
			clear: both;
			padding-top: 13px;
			padding-bottom: 70px;
		a{
			color: #2c3855;
			border: 1px solid #bc8a5f;
			padding-top: 13px;
			padding-bottom: 10px;
			padding-left: 30px;
			padding-right: 30px;
			text-transform: uppercase;
			-webkit-border-radius: 30px;
			-moz-border-radius: 30px;
			border-radius: 30px;		
		}		
	}
}
.webform-client-form-28,.webform-client-form-64{
	.btn-primary{
		margin-bottom: 0;
	}
}
.field-name-field-service-image{
	position: absolute;
	left: 0;
}
.page-usluga{
	.flexslider{
		border: none !important;
		margin: 0;
		.slides{
			img{
				-webkit-border-top-left-radius: 0px;
				-webkit-border-bottom-right-radius: 0px;
				-moz-border-radius-topleft: 0px;
				-moz-border-radius-bottomright:30px;
				border-top-left-radius: 0px;
				border-bottom-right-radius: 0px;
			}
			li{
				position: relative;
			}
		}
		.views-field-field-service-image{
			min-height: 555px;
			background: url(/sites/default/files/bg-usluga.png) no-repeat;
			img{
				visibility: hidden;
				display: none;
			}		
		}
		.views-field-field-banner-text, {
			z-index: 3;
			max-width: 1080px;
			margin: 0 auto;
	
			.field-content{
				top: 0;
				position: absolute;
				width: 25%;
				color: #ffffff;
				margin-top: 5%;
				.txt-1{
					font-size: 20px;
					text-transform: uppercase;
				}				
				.txt-2{
					font-size: 40px;
					text-transform: uppercase;
					font-weight: 700;
				}				
				.txt-3{
					font-size: 14px;
					line-height: 25px;
					margin-top: 40px;
					margin-bottom: 50px;
				}
				.read_more{
					background: #bc8a5f;
					color: #fff;
					font-family: Open Sans;
					text-transform: uppercase;
					border: 1px solid #bc8a5f;
					border-radius: 20px;
					padding-left: 30px;
					padding-right: 30px;
					margin-bottom: 70px;
					margin-top: 30px;
					padding-top: 11px;
					padding-bottom: 10px;					
				}
			}
		}
	} 
}
.page-articles{
	background: #fff;
	.page-header{
		border-bottom: none;
		margin-bottom:0px;
		padding-bottom:0px; 
		h1{
			font-family: Open Sans;
			font-weight: 700;
			text-transform: uppercase;
			color: #514c4e;
			margin: 0;
			font-size: 14px;	
			text-align: center;		
			color: #bc8a5f;
			margin-top: 75px;
			font-size: 14px;
			/*&:after{
				content: "Полезные статьи на различную тематику";
				color: #514c4e;
				font-size: 13px;
				text-transform: initial;
				clear: both;
				display: block;
				padding-top: 7px;
				font-family: Open Sans;
				font-weight: normal;
			}*/
		}		
	}
	.view-usefull-articles{ 
		.view-header {
			color: #514c4e;
		    font-size: 13px;
		    text-transform: initial;
		    clear: both;
		    display: block;
		    padding-top: 7px;
		    font-family: Open Sans;
		    font-weight: normal;
		    margin-bottom:18px;
		    text-align: center;
		}
	}
	.view-usefull-articles{
		margin-bottom: 70px;			
	}
}
#header{ 
	@media (max-width: 1000px) {
		min-height: 713px;
	}
	#main-menu {
		@media (max-width: 1000px) {
			float: left;
		}
	}
	.region-secondary-header-menu{
		@media (max-width: 630px) {
			width:100%;
			top: 10px;
		}
	}
	.nav > li > a, .nav > li > a:hover, .nav > li > a:focus{
		@media (max-width: 630px) {
			font-size: 16px;
		}
		@media (max-width: 480px) {
			font-size: 11px;
		}		
 	}
	.region-secondary-header-menu{
		li{
			@media (max-width: 480px) {
					background: url(../images/nf-sec-menu-2.png) no-repeat;
					background-position: 50% 0px;
			}
			a{
				@media (max-width: 480px) {
						/*background: none;*/
				}			
			}
		}
		li.first{
			@media (max-width: 480px) {
				background: url(../images/nf-sec-menu-1.png) no-repeat;
				background-position: center 0px;
				width: 80px;
			}			
		}		
		li.last{
			@media (max-width: 480px) {
				background: url(../images/nf-sec-menu-3.png) no-repeat;
				background-position: 50% 0px;
			}			
		}
	}
	.navbar-header.col-md-6{ 
		@media (max-width: 768px) {
			width: 100%;
			margin-top: 0;
			padding-top: 15px;
			background: #2c3855;
			padding-bottom: 20px;
			margin: 0;
			min-height: 130px;
		}
	}	
}
#footer{
	.bottom{
		img{
			@media (max-width: 480px) {
				width: 100% !important;
				height: auto !important;
			}			
		}
	}
}
.paragraphs-items-field-about-me, .field-name-field-why-choose-us{
	.col-md-2{
		@media (max-width: 990px) {
			width: 100% !important;
			margin-bottom: 20px;
		}		
	}
}
@media (max-width: 768px) {
	.container{
		padding-left: 0;
		padding-right: 0;
	}
}	
@media (max-width: 570px) {
	#header {
		.navbar-default {
			.navbar-brand {
				padding: 0;
				width: 80%;
			}
		}
		.language-switcher-locale-url{
			margin-left: 0;
			padding-left: 0;
		}
		.region-under-logo{
			width: 300px;
		}
		.region-under-logo{
			a.ctools-use-modal{
				color: #fff;
				font-family: "GothamProBlack";
				text-transform: uppercase;
				background: #bc8a5f;
				border-radius: 30px;
				padding-top: 7px;
				padding-bottom: 5px;
				font-size: 9px;
				padding-left: 20px;
				padding-right: 20px;
				position: relative;
				z-index: 3;
			}
		    position: absolute;
    		top: 0px; 
		}
		#logo{
			padding-top: 45px;
		}
	}
	.navbar-toggle{
		margin: 0;
	}
}
.not-front{
	#header{
		#main-menu{
			@media (max-width: 1024px) {

			}
		}
		.region-secondary-header-menu{
			@media (max-width: 800px) {
				width: 100%;				
			}				
			a{
				@media (max-width: 1000px) {
					font-size: 9px;
					background: none;
				}					
			}
			ul{
				li{
					@media (max-width: 1000px) {
						background-position: 50% 0 !important;				
					}
				}
				li.first{
					@media (max-width: 1000px) {
						background-position: center 0 !important;
					}
				}
				li.last{
					@media (max-width: 1000px) {
						background-position: 50% 0 !important;				
					}
				}				
			}
		}
	}
}
#webform-client-form-16,#webform-client-form-53{
	.webform-component--vashe-imya{
		input[type="text"]{
			@media (max-width: 1000px) {
				width: 100%;				
			}			
		}
	}
} 
.webform-client-form-28,
.webform-client-form-64{
	.webform-component--vashe-imya,.webform-component--vashe-imya{
			input[type="text"]{
				@media (max-width: 1000px) {
					width: 100%;				
				}
			}
	}
}
#webform-client-form-16, #webform-client-form-53{
	.webform-component--nomer-telefona,.webform-component--nomer-telefonu{
		input[type="text"]{
			@media (max-width: 1000px) {
				width: 100%;				
			}			
		}
	}
} 
.webform-client-form-28,
.webform-client-form-53,
.webform-client-form-64{
	.webform-component--nomer-telefona,
	.webform-component--nomer-telefonu{
			input[type="text"]{
				@media (max-width: 1000px) {
					width: 100%;				
				}
			}
	}
}
#webform-client-form-16,#webform-client-form-53{
	.webform-component--kommentariy,.webform-component--komentar{
		@media (max-width: 1000px) {
			left: 0;				
			margin-left: 0;
		}		
	}
}
.webform-client-form-28,
.webform-client-form-53,
.webform-client-form-64{
	.webform-component--kommentariy, 
	.webform-component--komentar{
		@media (max-width: 1000px) {
			left: 0;				
			margin-left: 0;		
		}
	}
}
#videomessage{ 
	.show_video{ 
		@media (max-width: 480px) {
			left: 0;				
			padding-left: 0;		
		}	
	}
	.field-name-field-video {
		display: none;
		top: 0px;
	    width: 100%;
	    position: absolute;
	    height: 100%;
	}
	.node-videomessage {
		padding-top:70px;
		@media (min-width: 768px) and (max-width: 1119px){
			padding-top:0px;
		}
		@media (max-width: 991px){
			padding-bottom: 40px;
			padding-top: 0px;
		}
		position:relative;
		iframe.media-youtube-player {
			max-width: inherit;
			width: 100%;
			height: 616px;
			@media (min-width: 992px) and (max-width: 1119px) {
			    height: 470px;
			}
			@media (min-width: 768px) and (max-width: 991px) {
			    height: 400px;
			}
		}
	}
}  
.page-usluga{
	.flexslider{
		.views-field-field-banner-text{
			@media (max-width: 767px){
				text-align: center;
			}
			.field-content{
				@media (max-width: 1700px) {
					margin-top: 7%;
				}				
				@media (max-width: 1200px) {
					margin-top: 2%;
				}					
				@media (max-width: 1000px) {
					margin-top: 2%;
					width: 100%;
				}			
				@media (max-width: 820px) {
					margin-top: 35%;
					width: 100%;
				}
				.txt-3{
					@media (max-width: 500px) {
						margin-top: 0;
					} 
				}
				.txt-2{
					@media (max-width: 500px) {
						font-size: 33px;
					}
				}				
			}
		}
	}
}
iframe.media-youtube-player{
	width: 100% !important;
	max-width: 900px;
}
div.ctools-modal-content{
	width: 320px !important;
}
div.ctools-modal-content{
	.modal-header{
    background-color: #bc8a5f;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding-top: 15px;
    text-align: center;
    padding-bottom: 10px;
    text-transform: uppercase;
		padding-bottom: 15px;
	}
}
#modalContent{
	#modal-content{
		border: none !important;
		border-radius: 0;
		border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;		
		background: #f8f4e7;
	}
	form{
		label{
			display: none;
		}
		.form-actions{
			text-align: center;
			.btn{
				background: none;
				color: #2c3855;
				font-family: Open Sans;
				text-transform: uppercase;
				border: 1px solid #bc8a5f;
				border-radius: 20px;
				padding-left: 30px;
				padding-right: 30px;
				margin-bottom: 30px;
				margin-top: 20px;
			}
		}
		.form-control{
			font-family: Open Sans;
			border: none;
			border-radius: 30px;
			margin-top: 20px;
		}
	}
}
div.ctools-modal-content{
		border: none !important;
		background: none;
}
.study_city{
	display: inline-block;
	padding-left: 10px;
	padding-right: 10px;
	background: #bc8a5f;
	color: #fff;
	font-family: Open Sans;
	font-weight: 400;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	font-size: 13px;
	padding-top: 8px;
	padding-bottom: 8px;
	line-height: 13px;
	position: absolute;
	left: 13px;
	bottom: 12px;
	width: 110px;
	text-align: center;	
}
.page-ya-obuchayu{
	.view-ya-gov {
		.wrop{
			.date-display-single{
				bottom: 50px;
			}
		}
	}
}
.views-field-field-i-study-video{
	text-align: center;
}
#photos{
	max-width: 1010px;
	margin-top: 50px;
}
.page-ya-obuchayu iframe.media-youtube-player{
	max-width: 945px;
}
.field-name-field-ispeak-image{
	max-width: 458px;
	img{
		width: 100% !important;
		height: auto !important;
	}
}
.views-field-field-service-image{
	img{
		width: 100% !important;
		height: auto !important;
		-webkit-border-top-left-radius: 30px;
		-webkit-border-bottom-right-radius: 30px;
		-moz-border-radius-topleft: 30px;
		-moz-border-radius-bottomright: 30px;
		border-top-left-radius: 30px;
		border-bottom-right-radius: 30px;		
	}
}
.view-id-i_healing{
    max-width: 900px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 100px;
    color: #514c4e;
    font-size: 14px;
    line-height: 24px;
}
.pane-i-healing h2{
    margin-top: 0;
    color: #bc8a5f !important;
    padding-top: 70px;
    padding-bottom: 25px;
}
.page-header{
	border-bottom: none;
	margin-bottom: 0 !important;
}
.page-ya-lechu{
	background: #fff;
	h1{
		font-family: Open Sans;
		font-weight: 700;
		text-transform: uppercase;
		color: #514c4e;
		margin: 0;
		font-size: 14px;
		text-align: center;
		color: #bc8a5f;
		margin-top: 75px;
		font-size: 14px;
	}
	.view-header {
		text-align: center;
	    color: #514c4e;
	    font-size: 13px;
	    text-transform: initial;
	    clear: both;
	    display: block;
	    padding-top: 7px;
	    font-family: Open Sans;
	    font-weight: normal; 
	    padding-bottom: 40px;	
	    padding-left:15px;
	}	
}
.navbar-default{
 .navbar-toggle{
 	border: none;
 	background: url(../images/bg-menu.png) no-repeat;
  	width: 47px;
  	height: 32px; 
  	@media (max-width: 570px) {
  		margin-top: 50px;	
  	}
  .icon-bar{
  	background-color: transparent;
  }
  &:hover{
  	background-color: transparent; 	
  }
  &:focus{
  	background-color: transparent; 	
  }  
 }
}
.node-type-articles{
	h2.pane-title{
		margin-bottom: 0px;
		padding-top: 140px;
	}
	.field-name-body{
		margin: 0 auto;
		margin-bottom: 70px;
		max-width: 900px;
		text-align: center;
	}
	.field-name-field-articles-date{
		text-align: center;
		margin-bottom: 40px;
	}
	.date-display-single{
		width: 80px;
		height: 80px;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		background: #2c3855;
		margin: 0 auto;
		position: relative;
		display: inline-block;
		.date-1, .date-2{
			font-family: Open Sans;
			font-weight: 400;
		}
		.date-1{
			color: #bc8a5f;
			font-size: 35px;
			line-height: 35px;	
			text-align: center;		
			position: relative;
			top: 12px;				
		}
		.date-2{
			font-size: 14px;
			color: #fff;
			line-height: 14px;
			text-align: center;
			position: relative;
			top: 12px;				
		}
		.field-content{

		}
		z-index: 2;	
	    position: absolute;
	    top: 40px;
	    left: 50%;
	    margin-left: -40px;
	}
	#content{
		position: relative;
	}
}
.views-field-field-banner{
	img{
		width: 100% !important;
		height: auto !important;
	}
}
